/* You can add global styles to this file, and also import other style files */

// ng-select2
@import "~@ng-select/ng-select/themes/default.theme.css";

// flatpicker
@import '~flatpickr/dist/flatpickr.css';

// Arch Wizard 
@import "~angular-archwizard/styles/archwizard.css";
@import '~ng-wizard/themes/ng_wizard_theme_arrows.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_circles.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_dots.min.css';

@import '~ng-wizard/themes/ng_wizard.min.css';

// Rating
.stars {
    font-size: 30px;
    color: var(--bs-gray);
}

.star {
    position: relative;
    display: inline-block;
}

.star_fill {
    color: var(--bs-gray);
}

.full {
    color: #b0c4de;
}

.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: var(--bs-gray);
}

.filled {
    color: #1e90ff;
}

.bad {
    color: #deb0b0;
}

.filled.bad {
    color: #ff1e1e;
}

.halfHeart {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: red;
}


// File Upload
.dropzone_sec {
    border: 2px dashed #e2e5e8 !important;
}

.dropzone .dz-message {
    font-size: 24px;
    min-height: 150px !important;
    border: none !important;
    background: transparent !important;
    color: var(--bs-gray-400) !important;
}

// Range Slider
.ng5-slider .ng5-slider-pointer {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    background-color: #5b73e8 !important;
    z-index: 3;
    border-radius: 16px;
}

.ng5-slider .ng5-slider-pointer:after {
    display: none !important;
}

.ng5-slider .ng5-slider-selection {
    background: #5b73e8 !important;
}

body[data-layout-mode=dark] {
    .dropzone {
        background: transparent;
    }

    .ng5-slider .ng5-slider-bar {
        background: var(--bs-gray-300);
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent !important;
    }

    // Select 2
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background-color: #253a55 !important;
        color: #8495ab !important;
    }

    .ng-select .ng-select-container {
        background-color: var(--bs-input-bg) !important;
        border: 1px solid var(--bs-input-border-color);
        color: var(--bs-body-color);
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: var(--bs-gray-100);
        color: var(--bs-body-color);
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: var(--bs-input-bg);
        color: var(--bs-body-color);
    }

    .ng-dropdown-panel {
        border: 1px solid var(--bs-gray-100);
    }

    // Sweet alert
    .swal2-popup {
        background: var(--bs-input-bg);
        color: var(--bs-body-color);
    }

    // Wizard
    .ng-wizard-theme-default .step-content,
    .ng-wizard-theme-default .ng-wizard-toolbar,
    .ng-wizard-theme-circles .step-content,
    .ng-wizard-theme-circles .ng-wizard-toolbar,
    .ng-wizard-theme-circles>ul.step-anchor {
        background-color: var(--bs-input-bg);
        color: var(--bs-body-color);
    }

}

.text-stp {
    color: #ffe300 !important;
}