@font-face {
  font-family: "CommissionerFlair-Regular";
  src: url("../../../../assets/fonts/Commissioner/CommissionerFlair-Regular.ttf");
}

@font-face {
  font-family: "CommissionerFlair-Medium";
  src: url("../../../../assets/fonts/Commissioner/CommissionerFlair-Medium.ttf");
}

@font-face {
  font-family: "CommissionerFlair-Bold";
  src: url("../../../../assets/fonts/Commissioner/CommissionerFlair-Bold.ttf");
}

@font-face {
  font-family: "Heimat-Bold";
  src: url("../../../../assets/fonts/Heimat/Heimat-Stencil-Bold.ttf");
}

@font-face {
  font-family: "Heimat-Light";
  src: url("../../../../assets/fonts/Heimat/Heimat-Stencil-ExtraLight.ttf");
}

body {
  font-family: "CommissionerFlair-Regular", Times, serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "CommissionerFlair-Bold", Times, serif !important;
}

.logo {
  font-size: 16px;
  line-height: 1 !important;
  font-family: "Heimat-Bold", Times, serif !important;

  span {
    &.st {
      margin-left: 0.2em;
      margin-top: 1.7em;
    }

    &.pass {
      margin-top: -15px;
    }
  }
}

.search-btn {
  span {
    position: relative;
    right: -30px;
  }
}

.col-form-label {
  font-weight: bold;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 9px;
  height: 28px;
  width: 28px;
  background: var(--bs-red);
  border-top-right-radius: 0.25rem;

  i {
    position: relative;
    color: white;
    top: -8px;
  }

  &.sub-btn {
    right: -18px !important;
    top: -18px !important;
    transform: scale(0.7) !important;
  }
}


.ts-08 {
  transform: scale(0.8) !important;
}

.cpr {
  cursor: pointer !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(180, 179, 179) !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(180, 179, 179) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(180, 179, 179) !important;
}


.set-minwh {
  min-height: calc(100vh - 150px);
}

.set-minwh-1 {
  min-height: calc(100vh - 350px);
}

.progress-bar {
  width: 100%;
  height: 11px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-size: 9px;
  line-height: 11px;
  padding-left: 10px;
}

.shadow {
  box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px !important;
}

.mnht-300 {
  min-height: 500px !important;
}

.invalid {
  border: 1px solid tomato !important;
}

.group-box {
  width: calc(100% - 24px);
  margin: 10px auto;
  padding-bottom: 1em;
}

.sub-list {
  position: absolute;
  right: 7px;
  top: -6px;
}

.align-center {
  align-items: center;
}

.drag-handle {
  i {
    font-size: 24px;
    position: absolute;
    top: 8px;
    left: 15px;
    cursor: move;
  }
}

.ow-hd {
  overflow: hidden;
}

.drag-list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.drag-box {
  padding: 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  // display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box:last-child {
  border: none;
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.preview-card {
  width: 300px;
  height: 100px;
}

.preview-img {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

input[readonly] {
  background-color: #f0f8ff;
}

input[readonly]:focus {
  background-color: #fff0f0;
}

ul.pagination {
  max-width: 50vw;
  float: right;
  overflow-y: hidden;
  overflow-x: scroll;
}
